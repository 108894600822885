exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-dinastia-js": () => import("./../../../src/pages/dinastia.js" /* webpackChunkName: "component---src-pages-dinastia-js" */),
  "component---src-pages-green-js": () => import("./../../../src/pages/green.js" /* webpackChunkName: "component---src-pages-green-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-politicaprivacidade-js": () => import("./../../../src/pages/politicaprivacidade.js" /* webpackChunkName: "component---src-pages-politicaprivacidade-js" */),
  "component---src-pages-termos-js": () => import("./../../../src/pages/termos.js" /* webpackChunkName: "component---src-pages-termos-js" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/postTemplate.js" /* webpackChunkName: "component---src-templates-post-template-js" */)
}

